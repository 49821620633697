import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../../components/button";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import { updateCurrentCompany } from "../../../redux/company/hook";
import {
  useExportAdvancedPayroll,
  useRefAdvancedPayroll,
  useRemoveAdvancedPayrollById,
  useSendPayslip,
  useSendSelectPayslip,
  useSendSinglePayslip,
  useSingleRecalculateAdvancedPayroll,
} from "../../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import PayrollSummary from "../../../components/modal/payroll/payroll-summary";
import AdvancedPayrollDetailedView from "../../../components/modal/payroll/payroll-full-breakdown-view";
import { useSelector } from "react-redux";
import { setIsLoading } from "../../../redux/components/components-slice";
import UnauthorizedPage from "../../errors/unauthorized";
import { store } from "../../../redux";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { toDataUrl } from "../../../utils/functions";
import TotalPayrollCosts from "../../../components/modal/payroll/total-payroll-payable";
import { useRouteQuery } from "../../../hooks/useRouteQuery";
import DoYouWantToModal from "../../../components/modal/do-you-want-to";
import PayslipPDFContent from "../../../utils/pdf-content/payslip";

const AdvancedPayrollPreviewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { advanced } = useSelector((state) => state.payrollSlice);

  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.payroll_read !== 1 ||
          user.permissions.payroll_permission?.advanced_payroll_read !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const [payrollStatus, setpayrollStatus] = useState("");
  const [showPayrollSummary, setShowPayrollSummary] = useState(false);
  const [showAdvancedPayrollDetailedView, setShowAdvancedPayrollDetailedView] =
    useState(false);
  const [
    showTotalAdvancedPayrollCostView,
    setShowTotalAdvancedPayrollCostView,
  ] = useState(false);

  const [previewData, setPreviewData] = useState();
  const [imageBlob, setImageBlob] = useState(null);

  const changeImage = async (imageLink) => {
    let urlBase64 = await toDataUrl(imageLink);
    setImageBlob(urlBase64);
  };

  useEffect(() => {
    if (company) {
      changeImage(company.logo);
    }
    return setImageBlob(null);
  }, [company]);

  const { mutateAsync: sendPayslip } = useSendSinglePayslip();
  const { mutateAsync: sendSelectPayslip } = useSendSelectPayslip();

  const { mutateAsync: sendBulkPayslip, isLoading: sendBulkPayslipLoading } =
    useSendPayslip();

  const query = useRouteQuery();

  useEffect(() => {
    if (query) {
      if (query.get("approve")) {
        setpayrollStatus("approve");
        setShowPayrollSummary(true);
      } else if (query.get("complete")) {
        setpayrollStatus("complete");
        setShowPayrollSummary(false);
      }
    }
  }, [query]);

  const printHandler = (index) => {
    store.dispatch(setIsLoading(true));
    console.log(JSON.stringify(advancedPayroll.data[index]));

    console.log(JSON.stringify(company));
    console.log(JSON.stringify(imageBlob));

    const printElement = ReactDOMServer.renderToString(
      PayslipPDFContent(company, advancedPayroll.data[index], imageBlob)
    );
    var opt = {
      margin: 0,
      filename: advancedPayroll.data[index]?.ref + " - payslip.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      pagebreak: { mode: "avoid-all", before: "#page2el" },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      enableLinks: true
    };
    html2pdf().set(opt).from(printElement).save();
    store.dispatch(setIsLoading(false));
  };

  const { refId } = useParams();
  const {
    refetch,
    data: advancedPayroll,
    isLoading: loading,
  } = useRefAdvancedPayroll(
    refId,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  useEffect(() => {
    updateCurrentCompany();
  }, []);

  const tableConfig = {
    headers: [
      "Employee Name",
      "Bank Info",
      "Basic",
      "Transport",
      "Housing",
      "Others",
      "Gross Pay",
      "Tax",
      "Pension",
      "Net Pay",
    ],
    keys: [
      "employeeName",
      "bank_info",
      "formatted_basic_salary",
      "formatted_transport_allowance",
      "formatted_housing_allowance",
      "formatted_other_accum_allowance",
      "formatted_gross_pay",
      "formatted_tax",
      "formatted_pension",
      "formatted_net_pay",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "  Net Pay",
        key: "net_pay",
      },
    },
  };

  const workbook = new Excel.Workbook();

  const [exportLoading, setSetExportLoading] = useState(false);

  const { mutateAsync: exportPayroll } = useExportAdvancedPayroll();

  const exportAdvancedPayroll = async (refId) => {
    setSetExportLoading(true);
    let payload = {
      refId,
      limit: 100000,
      page: 1,
      statusFilter: -1,
      search: "",
    };

    await exportPayroll(payload).then(async (response) => {
      let data = response.data;
      const workSheetName = "advanced-payroll-history";
      const workBookName = `Advanced Payroll - ${refId}`;
      let columns = [
        { header: "Date", key: "formatted_date" },
        { header: "First Name", key: "employee_first_name" },
        { header: "Last Name", key: "employee_last_name" },
        { header: "Mobile", key: "employee_phone" },
        { header: "Staff Number", key: "staff_number" },
        { header: "Bank Name", key: "bank_name" },
        { header: "Account Number", key: "account_number" },
        { header: "Basic Salary", key: "formatted_basic_salary" },
        { header: "Housing Allowance", key: "formatted_housing_allowance" },
        { header: "Transport Allowance", key: "formatted_transport_allowance" },
        { header: "Leave Allowance", key: "formatted_leave_allowance" },
        { header: "Lunch Allowance", key: "formatted_lunch_allowance" },
        { header: "Benefit In Kind", key: "formatted_benefit_in_kind" },
        { header: "Utility Allowance", key: "formatted_utility_allowance" },
        { header: "Other Allowance", key: "formatted_other_allowance" },
      ];

      data.forEach((item) => {
        let newAllowanceItem = [];
        if (
          item.additional_allowances &&
          JSON.parse(item.additional_allowances.length > 0)
        ) {
          JSON.parse(item.additional_allowances).forEach((subItem) => {
            let description = subItem.title;
            let headersArray = [];
            columns.forEach((columnHeaders) => {
              headersArray.push(columnHeaders.header);
            });
            for (let i = 0; i < columns.length; i++) {
              if (!headersArray.includes(description)) {
                let singleItemList = null;
                if (newAllowanceItem.length > 0) {
                  for (let j = 0; j < newAllowanceItem.length; j++) {
                    if (newAllowanceItem[j].header == description) {
                      if (
                        description !== "Leave Allowance" ||
                        description !== "Lunch Allowance" ||
                        description !== "Benefit In Kind" ||
                        description !== "Utility Allowance" ||
                        description !== "Other Allowance"
                      ) {
                        singleItemList = null;
                        break;
                      } else {
                        description = description + "(1)";
                        if (newAllowanceItem[j].header == description) {
                          singleItemList = null;
                          break;
                        } else {
                          singleItemList = {
                            header: description,
                            key: description,
                          };
                        }
                      }
                    } else {
                      singleItemList = {
                        header: description,
                        key: description,
                      };
                    }
                  }
                } else {
                  singleItemList = { header: description, key: description };
                }
                if (singleItemList) {
                  newAllowanceItem.push(singleItemList);
                }
              }
            }
          });
          columns.push(...newAllowanceItem);
        }

        let newBonusItem = [];
        if (item.bonus && item.bonus > 0) {
          if (
            item.bonus_breakdown &&
            JSON.parse(item.bonus_breakdown.length > 0)
          ) {
            JSON.parse(item.bonus_breakdown).forEach((subItem) => {
              let description = subItem.description;
              let headersArray = [];
              columns.forEach((columnHeaders) => {
                headersArray.push(columnHeaders.header);
              });
              for (let i = 0; i < columns.length; i++) {
                if (!headersArray.includes(description)) {
                  let singleItemList = null;
                  if (newBonusItem.length > 0) {
                    for (let j = 0; j < newBonusItem.length; j++) {
                      if (newBonusItem[j].header == description) {
                        if (
                          description !== "Leave Allowance" ||
                          description !== "Lunch Allowance" ||
                          description !== "Benefit In Kind" ||
                          description !== "Utility Allowance" ||
                          description !== "Other Allowance"
                        ) {
                          singleItemList = null;
                          break;
                        } else {
                          description = description + "(1)";
                          if (newBonusItem[j].header == description) {
                            singleItemList = null;
                            break;
                          } else {
                            singleItemList = {
                              header: description,
                              key: description,
                            };
                          }
                        }
                      } else {
                        singleItemList = {
                          header: description,
                          key: description,
                        };
                      }
                    }
                  } else {
                    singleItemList = { header: description, key: description };
                  }
                  if (singleItemList) {
                    newBonusItem.push(singleItemList);
                  }
                }
              }
            });
            columns.push(...newBonusItem);
          } else {
            columns.push({
              header: "Bonus",
              key: "bonus",
            });
          }
        }
      });

      let gArray = [
        { header: "Gross Pay", key: "formatted_gross_pay" },
        { header: "Employee Pension", key: "formatted_pension" },
        { header: "PAYE Tax", key: "formatted_tax" },
        { header: "National Housing Fund", key: "formatted_nhf" },
        { header: "National Health Insurance Scheme", key: "formatted_nhis" },
        { header: "Salary Advance", key: "formatted_salary_advance" },
      ];

      columns.push(...gArray);

      data.forEach((item) => {
        let newOtherDeductionItem = [];
        if (item.other_deductions && item.other_deductions > 0) {
          if (
            item.other_deductions_breakdown &&
            JSON.parse(item.other_deductions_breakdown.length > 0)
          ) {
            JSON.parse(item.other_deductions_breakdown).forEach((subItem) => {
              let description = subItem.description;
              let headersArray = [];
              columns.forEach((columnHeaders) => {
                headersArray.push(columnHeaders.header);
              });
              for (let i = 0; i < columns.length; i++) {
                if (!headersArray.includes(description)) {
                  let singleItemList = null;
                  if (newOtherDeductionItem.length > 0) {
                    for (let j = 0; j < newOtherDeductionItem.length; j++) {
                      if (newOtherDeductionItem[j].header == description) {
                        if (
                          description !== "Leave Allowance" ||
                          description !== "Lunch Allowance" ||
                          description !== "Benefit In Kind" ||
                          description !== "Utility Allowance" ||
                          description !== "Other Allowance"
                        ) {
                          singleItemList = null;
                          break;
                        } else {
                          description = description + "(1)";
                          if (newOtherDeductionItem[j].header == description) {
                            singleItemList = null;
                            break;
                          } else {
                            singleItemList = {
                              header: description,
                              key: description,
                            };
                          }
                        }
                      } else {
                        singleItemList = {
                          header: description,
                          key: description,
                        };
                      }
                    }
                  } else {
                    singleItemList = { header: description, key: description };
                  }
                  if (singleItemList) {
                    newOtherDeductionItem.push(singleItemList);
                  }
                }
              }
            });
            columns.push(...newOtherDeductionItem);
          } else {
            columns.push({
              header: "Other Deductions",
              key: "other_deductions",
            });
          }
        }

        let newReimbursementItem = [];
        if (item.reimburse && item.reimburse > 0) {
          if (
            item.reimbursement_breakdown &&
            JSON.parse(item.reimbursement_breakdown.length > 0)
          ) {
            JSON.parse(item.reimbursement_breakdown).forEach((subItem) => {
              let description = subItem.description;
              let headersArray = [];
              columns.forEach((columnHeaders) => {
                headersArray.push(columnHeaders.header);
              });
              for (let i = 0; i < columns.length; i++) {
                if (!headersArray.includes(description)) {
                  let singleItemList = null;
                  if (newReimbursementItem.length > 0) {
                    for (let j = 0; j < newReimbursementItem.length; j++) {
                      if (newReimbursementItem[j].header == description) {
                        if (
                          description !== "Leave Allowance" ||
                          description !== "Lunch Allowance" ||
                          description !== "Benefit In Kind" ||
                          description !== "Utility Allowance" ||
                          description !== "Other Allowance"
                        ) {
                          singleItemList = null;
                          break;
                        } else {
                          description = description + "(1)";
                          if (newReimbursementItem[j].header == description) {
                            singleItemList = null;
                            break;
                          } else {
                            singleItemList = {
                              header: description,
                              key: description,
                            };
                          }
                        }
                      } else {
                        singleItemList = {
                          header: description,
                          key: description,
                        };
                      }
                    }
                  } else {
                    singleItemList = { header: description, key: description };
                  }
                  if (singleItemList) {
                    newReimbursementItem.push(singleItemList);
                  }
                }
              }
            });
            columns.push(...newReimbursementItem);
          } else {
            columns.push({
              header: "Reimbursements",
              key: "reimburse",
            });
          }
        }
      });

      let finArray = [
        { header: "Net Pay", key: "formatted_net_pay" },
        { header: "Payments Status", key: "status" },
        { header: "Employer Pension", key: "formatted_employer_pension" },
        { header: "Industrial Training Fund", key: "formatted_itf" },
        {
          header: "Nigeria Social Insurance Trust Fund",
          key: "formatted_nsitf",
        },
      ];

      columns.push(...finArray);

      try {
        const fileName = workBookName;

        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = { bold: true };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column) => {
          column.width = 23;
          if (column._number > 7) {
            column.width = 23;
            column.style.alignment = { horizontal: "right" };
            column.numFmt = "#,##0.00;[Red]-#,##0.00";
          } else {
            column.width = column.header.length + 2;
            column.style.alignment = { horizontal: "left" };
          }
        });

        // loop through data and add each one to worksheet
        data.forEach((singleData) => {
          if (
            singleData.additional_allowances &&
            JSON.parse(singleData.additional_allowances.length > 0)
          ) {
            JSON.parse(singleData.additional_allowances).forEach((item) => {
              singleData[item.title] = parseFloat(item.amount);
            });
          }

          if (singleData.bonus && singleData.bonus > 0) {
            if (
              singleData.bonus_breakdown &&
              JSON.parse(singleData.bonus_breakdown.length > 0)
            ) {
              JSON.parse(singleData.bonus_breakdown).forEach((item) => {
                singleData[item.description] = parseFloat(item.amount);
              });
            }
          }

          if (singleData.other_deductions && singleData.other_deductions > 0) {
            if (
              singleData.other_deductions_breakdown &&
              JSON.parse(singleData.other_deductions_breakdown.length > 0)
            ) {
              JSON.parse(singleData.other_deductions_breakdown).forEach(
                (item) => {
                  singleData[item.description] = parseFloat(item.amount);
                }
              );
            }
          }

          if (singleData.reimburse && singleData.reimburse > 0) {
            if (
              singleData.reimbursement_breakdown &&
              JSON.parse(singleData.reimbursement_breakdown.length > 0)
            ) {
              JSON.parse(singleData.reimbursement_breakdown).forEach((item) => {
                singleData[item.description] = parseFloat(item.amount);
              });
            }
          }
          singleData.formatted_date = moment(singleData.created).format(
            "MMMM DD, YYYY h:m:s"
          );

          if (singleData.status === 1) {
            singleData.status = "Successful";
          } else if (singleData.status === 2) {
            singleData.status = "Failed";
          } else if (singleData.status === 0) {
            singleData.status = "Pending";
          }
          worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, (row) => {
          // store each cell to currentCell
          const currentCell = row._cells;

          // loop through currentCell to apply border only for the non-empty cell of excel
          currentCell.forEach((singleCell) => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
            // const cellAddress = singleCell._address;
            // // apply border
            // worksheet.getCell(cellAddress).border = {
            //   top: { style: 'thin' },
            //   left: { style: 'thin' },
            //   bottom: { style: 'thin' },
            //   right: { style: 'thin' }
            // };
          });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        // console.error('<<<ERRROR>>>', error);
        // console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet(workSheetName);
      }
    });
    setSetExportLoading(false);
  };

  const [showDoYouWant, setShowDoYouWant] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { mutateAsync: recalculate, isLoading: recalculateLoading } =
    useSingleRecalculateAdvancedPayroll();

  const actionConfig = [
    {
      name: "Detailed",
      color: "green",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        setPreviewData(advancedPayroll.data[index]);
        setShowAdvancedPayrollDetailedView(true);
      },
    },
    {
      name: "Recalculate",
      color: "green",
      action: async (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        await recalculate({
          ref: advancedPayroll.data[index].ref,
          id: advancedPayroll.data[index].id,
        }).then(() => {
          refetch();
        });
      },
    },
    {
      name: "Download Payslip",
      color: "red",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        if (index !== -1) {
          printHandler(index);
        }
      },
    },
    {
      name: "Send Payslip",
      color: "orange",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        sendPayslip({ id: advancedPayroll.data[index].id });
      },
    },
    {
      name: "Remove",
      color: "red",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        setDeleteId(advancedPayroll.data[index].id);
        setShowDoYouWant(true);
      },
    },
  ];

  const bulkActionConfig = [
    {
      label: "Send Payslips",
      value: "send-select-payslips",
      action: (data) => {
        let newData = data.split(",");
        let result = [];

        newData.forEach((id) => {
          let index = advancedPayroll.data.findIndex((item) => item.id == id);
          if (index != -1) {
            result.push(advancedPayroll.data[index].id);
          }
        });
        sendSelectPayslip({ salary_ids: result.join(",") });
      },
    },
  ];

  const { mutateAsync: deleteAdvancedSalary, isLoading: deleteLoading } =
    useRemoveAdvancedPayrollById();

  const deleteSalary = async (id) => {
    let index = advancedPayroll.data.findIndex((item) => item.id === id);
    await deleteAdvancedSalary(advancedPayroll.data[index].id).then(() => {
      setShowDoYouWant(false);
    });
  };

  return (
    <DashboardLayout title="Advanced Payroll Preview">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {previewData && showAdvancedPayrollDetailedView ? (
            <AdvancedPayrollDetailedView
              isOpen={showAdvancedPayrollDetailedView}
              closeModal={() => setShowAdvancedPayrollDetailedView(false)}
              viewData={previewData}
            />
          ) : null}
          {previewData && showTotalAdvancedPayrollCostView ? (
            <TotalPayrollCosts
              isOpen={showTotalAdvancedPayrollCostView}
              closeModal={() => setShowTotalAdvancedPayrollCostView(false)}
              viewData={previewData}
            />
          ) : null}
          {showDoYouWant ? (
            <DoYouWantToModal
              isOpen={showDoYouWant}
              closeModal={() => setShowDoYouWant(false)}
              id={deleteId}
              loading={deleteLoading}
              title={
                "Are you sure you want to delete this salary from the list?"
              }
              subtitle={"No refund would be made for charges already incurred"}
              buttonText={"REMOVE"}
              btnFunction={deleteSalary}
            />
          ) : null}
          <div className="flex flex-col">
            <div className="flex md:flex-row flex-col justify-between items-center mb-2 gap-3">
              <div className="flex flex-col justify-between md:items-start items-center">
                <span className="h4 text-color-black">
                  A Quick Preview Before You Submit
                </span>
                <span className="p4 text-color-gray -mt-2">
                  Please spend a brief moment reviewing these numbers before you
                  submit payroll for payment
                </span>
              </div>
              {user?.permissions.payroll_permission?.advanced_payroll_write ===
                1 || user?.permissions.company_permission.role === "Owner" ? (
                <div className="w-full md:w-[220px] md:mt-0 h-[56px]">
                  <Button
                    text={
                      payrollStatus == "approve"
                        ? "Approve Payroll"
                        : payrollStatus == "complete"
                          ? "Complete Payment"
                          : "Pay Now"
                    }
                    type="button"
                    loading={false}
                    disabled={false}
                    className="h-[56px]"
                    onClick={() => {
                      setShowPayrollSummary(true);
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="mt-4">
              <>
                {showPayrollSummary ? (
                  <PayrollSummary
                    refValue={refId}
                    type="advanced"
                    source={advancedPayroll?.source}
                    source_id={advancedPayroll?.source_id}
                    payrollStatus={payrollStatus}
                    isOpen={showPayrollSummary}
                    closeModal={() => setShowPayrollSummary(false)}
                    takeHome={advancedPayroll?.total_net_pay}
                    transCost={advancedPayroll?.total_trans_cost}
                    totalAmount={
                      advanced?.status == 7
                        ? advancedPayroll?.total_payable
                        : advancedPayroll?.total_payable
                    }
                  />
                ) : null}

                <div className="flex flex-col bg-white rounded-md">
                  <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                    <div className="p3-bold header-4">Payroll Breakdown</div>
                    <div className="flex flex-col md:flex-row items-start gap-[10px] md:justify-end md:w-[60%] w-full">
                      <div className="md:w-fit w-[100%]">
                        <Button
                          text="Send Payslips"
                          type="submit"
                          theme="primary"
                          className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                          textClass={"!text-[11px]"}
                          leftIcon={"email"}
                          loading={sendBulkPayslipLoading}
                          onClick={() => sendBulkPayslip({ ref: refId })}
                        />
                      </div>
                      <div className="md:w-fit w-[100%]">
                        <Button
                          text="View All Payment"
                          type="submit"
                          theme="primary"
                          className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                          textClass={"!text-[11px]"}
                          leftIcon={"view"}
                          onClick={() => {
                            setPreviewData(advancedPayroll);
                            setShowTotalAdvancedPayrollCostView(true);
                          }}
                        />
                      </div>
                      <div className="md:w-fit w-[100%]">
                        <Button
                          text="Export"
                          type="submit"
                          theme="primary"
                          className="flex gap-2 !h-[35px] md:w-fit w-full px-2 items-center"
                          textClass={"!text-[11px]"}
                          leftIcon={"export"}
                          onClick={() => {
                            exportAdvancedPayroll(refId);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="divider mt-2 mb-2" />
                  <div className="w-[100%]">
                    <DataTable
                      data={advancedPayroll}
                      tableConfig={tableConfig}
                      actionConfig={actionConfig}
                      searchData={true}
                      bulkActionConfig={bulkActionConfig}
                      updatePagination={(data) => setPagination(data)}
                      computedActions={true}
                    />
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default AdvancedPayrollPreviewPage;
