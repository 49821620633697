import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DataTable from "../../../components/datatable";
import DashboardLayout from "../../../components/layouts/dashboard";
import {
  useFetchAdvancedPayrollById,
  useSendSinglePayslip,
} from "../../../redux/payroll/hook";
import { PAGINATION_DEFAULT } from "../../../utils/constants";
import AdvancedPayrollDetailedView from "../../../components/modal/payroll/payroll-full-breakdown-view";
import { useSelector } from "react-redux";
import {
  isLoading,
  setIsLoading,
} from "../../../redux/components/components-slice";
import UnauthorizedPage from "../../errors/unauthorized";
import AdvancedPayrollPayments from "../../../components/modal/payroll/advanced-payroll-payments";
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import ReactDOMServer from "react-dom/server";
import { store } from "../../../redux";
import PayslipPDFContent from "../../../utils/pdf-content/payslip";

const AdvancedPayrollViewPage = () => {
  const { user } = useSelector((state) => state.userSlice);
  const { company } = useSelector((state) => state.companySlice);

  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.permissions.company_permission.role !== "Owner") {
        if (
          user.permissions.company_permission.payroll_read !== 1 ||
          user.permissions.payroll_permission?.advanced_payroll_read !== 1
        ) {
          setUnauthorized(true);
        }
      }
    }
  }, [user]);

  const loading = useSelector(isLoading);
  // const [exportLoading, setExportLoading] = useState(false)

  const [pagination, setPagination] = useState({
    limit: PAGINATION_DEFAULT.limit,
    page: PAGINATION_DEFAULT.page,
    statusFilter: PAGINATION_DEFAULT.statusFilter,
    search: "",
  });

  const printHandler = (index) => {
    store.dispatch(setIsLoading(true));
    const printElement = ReactDOMServer.renderToString(
      PayslipPDFContent(company, advancedPayroll.data[index])
    );
    var opt = {
      margin: 0,
      filename: advancedPayroll.data[index]?.ref + " - payslip.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      pagebreak: { mode: "avoid-all", before: "#page2el" },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(opt).from(printElement).save();
    store.dispatch(setIsLoading(false));
  };

  const [showAdvancedPayrollDetailedView, setShowAdvancedPayrollDetailedView] =
    useState(false);
  const [showAdvancedPayrollPayments, setShowAdvancedPayrollPayments] =
    useState(false);
  const [previewData, setPreviewData] = useState();

  const { mutateAsync: sendPayslip } = useSendSinglePayslip();

  const { id } = useParams();
  const { data: advancedPayroll } = useFetchAdvancedPayrollById(
    id,
    pagination?.limit,
    pagination?.page,
    pagination?.statusFilter,
    pagination?.search
  );

  const tableConfig = {
    headers: [
      "Employee Name",
      "Mobile",
      "Basic",
      "Transport",
      "Housing",
      "Gross Pay",
      "Tax",
      "Pension",
      "Net Pay",
    ],
    keys: [
      "employeeName",
      "employee_phone",
      "formatted_basic_salary",
      "formatted_transport_allowance",
      "formatted_housing_allowance",
      "formatted_gross_pay",
      "formatted_tax",
      "formatted_pension",
      "formatted_net_pay",
    ],
    mobileHeader: {
      left: {
        title: "Employee Name",
        key: "employeeName",
      },
      right: {
        title: "  Net Pay",
        key: "net_pay",
      },
    },
  };

  const actionConfig = [
    {
      name: "Details",
      color: "green",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        setPreviewData(advancedPayroll.data[index]);
        setShowAdvancedPayrollDetailedView(true);
      },
    },
    {
      name: "Download Payslip",
      color: "red",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        if (index !== -1) {
          printHandler(index);
        }
      },
    },
    {
      name: "Send Payslip",
      color: "orange",
      action: (id) => {
        let index = advancedPayroll.data.findIndex((item) => item.id === id);
        sendPayslip({ id: advancedPayroll.data[index].id });
      },
    },
  ];

  return (
    <DashboardLayout title="Advanced Payroll View">
      {unauthorized ? (
        <UnauthorizedPage />
      ) : (
        <>
          {previewData && showAdvancedPayrollDetailedView ? (
            <AdvancedPayrollDetailedView
              isOpen={showAdvancedPayrollDetailedView}
              closeModal={() => setShowAdvancedPayrollDetailedView(false)}
              viewData={previewData}
            />
          ) : null}
          {previewData && showAdvancedPayrollPayments ? (
            <AdvancedPayrollPayments
              isOpen={showAdvancedPayrollPayments}
              closeModal={() => setShowAdvancedPayrollPayments(false)}
              viewData={previewData}
            />
          ) : null}
          <div className="flex flex-col">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col bg-white rounded">
                <div className="flex flex-col md:flex-row justify-between items-center px-4 gap-[10px] my-2 mt-3">
                  <div className="p3-bold header-4">
                    Advanced Payroll Breakdown
                  </div>
                  {/* <div className='flex flex-col md:flex-row items-start gap-[10px] md:items-center'>
                <div className='md:w-fit w-[100%]'>
                  <Button
                  text='Export'
                  type='submit'
                  theme='primary'
                  className='flex gap-2 !h-[35px] w-fit p-2 '
                  textClass={'!text-[11px]'}
                  leftIcon={'export'}
                  onClick={() => ""}
                  />
                </div>
              </div> */}
                </div>
                <hr className="divider mt-2 mb-2" />
                <div className="w-[100%]">
                  <DataTable
                    data={advancedPayroll}
                    tableConfig={tableConfig}
                    noCheck={true}
                    actionConfig={actionConfig}
                    updatePagination={(data) => setPagination(data)}
                    computedActions={true}
                    searchData={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default AdvancedPayrollViewPage;
