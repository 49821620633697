import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CloseSVG from '../../../assets/svg/close.svg';
import Button from '../../button';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WarningSVG from '../../../assets/svg/warning.svg';
import ConfirmationModal from '../confirmation-modal';
import {
  useDisburseAirtime,
  useInitiateAirtime,
} from '../../../redux/airtime/hook';
import Modal from '../modal';
import { formatNumber } from '../../../utils/functions';
import FormOtpInput from '../../form-otp-input';

function AirtimeSummary({
  isOpen,
  closeModal,
  totalAirtime,
  airtimeStatus,
  totalAmount,
  refValue,
}) {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const [pin, setPIN] = useState('');

  const history = useHistory();
  const { company } = useSelector((state) => state.companySlice);
  const { user } = useSelector((state) => state.userSlice);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { mutateAsync: initiateAirtime, isLoading: initiateLoading } =
    useInitiateAirtime();

  const { mutateAsync: disburseAirtime, isLoading: disburseLoading } =
    useDisburseAirtime();

  const submitForm = async (data) => {
    let requestData = {
      transaction_pin: pin,
      ref: data,
    };
    if (
      user?.permissions.approval_permission?.initiator === 1 &&
      user?.permissions.approval_permission?.approver === 1
    ) {
      await disburseAirtime(requestData).then(() => {
        setShowConfirmation(true);
      });
    } else if (
      user?.permissions.approval_permission?.initiator === 1 &&
      airtimeStatus !== 'approve'
    ) {
      await initiateAirtime(requestData).then(() => {
        setShowConfirmation(true);
      });
    } else if (
      user?.permissions.approval_permission?.approver === 1 &&
      airtimeStatus === 'approve'
    ) {
      await disburseAirtime(requestData).then(() => {
        setShowConfirmation(true);
      });
    }
  };
  const confirmationNext = () => {
    setShowConfirmation(false);
    history.push('/airtime');
  };
  const subTitle = `Time to kick back and relax. Ropay will debit ${totalAmount} from your account. Please Make sure the funds are available.`;

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmation}
        closeModal={() => setShowConfirmation(false)}
        btnFunction={confirmationNext}
        title={'You did it, ' + user?.first_name}
        subtitle={subTitle}
      />
      <Modal
        scrollable={true}
        className='!max-w-[600px] !rounded-none pb-3'
        isOpen={isOpen}
        centered={true}
      >
        <div className='flex flex-col !max-h-[600px] overflow-auto'>
          <div className='pl-8 pr-8 pt-4'>
            <div className='flex flex-row justify-between items-center'>
              <span className='header-4'>Pay</span>
              <div className='cursor-pointer' onClick={() => closeModal()}>
                <img src={CloseSVG} alt='close' />
              </div>
            </div>
          </div>
          <hr className='divider' />
          <div className='flex flex-col mx-8'>
            <div className='flex flex-col'>
              <div className='flex flex-col items-center'>
                <span className='stat-card-header'>WALLET BALANCE</span>
                <span className='p4-medium  text-color-black'>
                  {formatNumber(company.balance, 2)}
                </span>
              </div>
              <hr className='divider' />

              <div className='flex flex-col items-center mt-[2%]'>
                <span className='stat-card-header'>TOTAL AIRTIME</span>
                <span className='p4-medium  text-color-black'>
                  {totalAirtime}
                </span>
              </div>
              <hr className='divider' />

              <div className='flex flex-col items-center mt-[2%]'>
                <span className='stat-card-header'>TOTAL FEES</span>
                <span className='p4-medium text-color-black'>{'0.00'}</span>
              </div>
            </div>
            <hr className='divider' />
            <div className='flex flex-col justify-center mb-8'>
              <div className='flex flex-row px-4 border-secondary-4 border-[2px] rounded-lg p-4 mt-8 bg-color-warning-light'>
                <div className='w-[70px] pr-2 flex flex-row items-center '>
                  <img src={WarningSVG} alt='' srcSet='' />
                </div>
                <span>
                  Ropay will debit {totalAmount} from your account. Please Make
                  sure the funds are available.
                </span>
              </div>
            </div>
            {airtimeStatus === 'approve' &&
            user?.permissions.approval_permission?.approver === 1 ? (
              <div className='w-full mb-[20px]'>
                <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                  <FormOtpInput
                    value={pin}
                    valueLength={4}
                    onChange={(value) => setPIN(value)}
                    label={'Enter your new 4 digit PIN'}
                  />
                </div>
                <Button
                  text={'APPROVE PAYMENT'}
                  type='submit'
                  disabled={false}
                  loading={initiateLoading || disburseLoading}
                  onClick={() => submitForm(refValue)}
                />
              </div>
            ) : airtimeStatus !== 'approve' &&
              user?.permissions.approval_permission?.initiator === 1 ? (
              <div className='w-full mb-[20px]'>
                <div className='flex w-[100%] justify-center border bg-highlight p-2 rounded-md mb-4'>
                  <FormOtpInput
                    value={pin}
                    valueLength={4}
                    onChange={(value) => setPIN(value)}
                    label={'Enter your new 4 digit PIN'}
                  />
                </div>
                <Button
                  text={
                    user?.permissions.approval_permission?.initiator === 1 &&
                    user?.permissions.approval_permission?.approver === 1
                      ? 'PAY'
                      : 'INITIATE PAYMENT'
                  }
                  type='submit'
                  disabled={false}
                  loading={initiateLoading || disburseLoading}
                  onClick={() => submitForm(refValue)}
                />
              </div>
            ) : airtimeStatus !== 'approve' &&
              user?.permissions.approval_permission?.approver === 1 ? (
              <div className='text-center w-full mb-[20px]'>
                You are not authorised to Initiate payments <br /> Please
                contact your adminsistrator.
              </div>
            ) : (
              <div className='text-center  w-full mb-[20px]'>
                You are not Authorised to Approve payment <br /> Please contact
                your adminsistrator.
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AirtimeSummary;
