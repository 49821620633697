import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import FormInput from '../../components/form-input';
import Button from '../../components/button';
import { formValidation } from '../../utils/functions';
import AuthLayout from '../../components/layouts/auth';
import { useSignUp } from '../../redux/user/hook';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteQuery } from '../../hooks/useRouteQuery';

const RegisterPage = () => {
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    errors,
  } = useForm({
    mode: 'onChange',
  });

  const query = useRouteQuery();

  const { section } = useSelector((state) => state.sectionSlice);
  const { webToken } = useSelector((state) => state.userSlice);

  const [Password, setPassword] = useState('');
  const [terms, setTerms] = useState();
  const [referral_code, setReferralCode] = useState();
  const [confirmPassword, setConfirmPassword] = useState('');
  const { mutate: signUp, isLoading: loading } = useSignUp();

  useEffect(() => {
    if (query) {
      setReferralCode(query.get('referral_code'));
    }
  }, [query]);

  const submitForm = async (data) => {
    const first_name = DOMPurify.sanitize(data?.first_name);
    const last_name = DOMPurify.sanitize(data?.last_name);
    const company_name = DOMPurify.sanitize(data?.company_name);
    const mobile = DOMPurify.sanitize(data?.mobile);
    const email = DOMPurify.sanitize(data?.email);
    const password = DOMPurify.sanitize(data?.password);
    const source = 'web';
    const web_token = webToken ? webToken : 'no_token';

    const requestData = {
      first_name,
      last_name,
      company_name,
      mobile,
      email,
      password,
      terms: terms ? 'on' : '',
      source,
      web_token,
      referral_code,
    };

    signUp(requestData);
  };

  return (
    <AuthLayout>
      <form className='form' onSubmit={handleSubmit(submitForm)}>
        <div className='w-full text-center flex flex-col pb-[20px]'>
          <span className={'text-[25px] font-bold text-secondary-2'}>
            Create your account
          </span>
          <span className='flex items-center justify-center text-[13px] text-color-gray mb-3'>
            Already have an account?
            <Link to='/' className='hover:underline duration-300'>
              <span className='text-[15px] font-extrabold'>&ensp;Sign In</span>
            </Link>
          </span>

          {/* <Button
            text='Sign up with Google'
            leftIcon={'googleIcon'}
            className={'gap-4'}
            textClass={'!capitalize'}
            type='button'
            loading={loading}
            onClick={() => {
              window.location.assign(`https://prod.ropay.io/google_auth`);
            }}
          /> */}
        </div>
        <div className='flex flex-col md:flex-row justify-between'>
          <div className='w-full md:w-1/2 md:pr-2'>
            <FormInput
              label='First name'
              name='first_name'
              placeholder={'Enter your first name'}
              type='text'
              inputRef={register(formValidation('text', true))}
              readOnly={loading}
              error={errors.first_name}
              errorMessage={errors.first_name && errors.first_name.message}
            />
          </div>
          <div className='w-full md:w-1/2 md:pl-2'>
            <FormInput
              label='Last name'
              name='last_name'
              type='text'
              placeholder={'Enter your last name'}
              inputRef={register(formValidation('text', true))}
              readOnly={loading}
              error={errors.last_name}
              errorMessage={errors.last_name && errors.last_name.message}
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-between'>
          <div className='w-full md:w-2/3 md:pr-2'>
            <FormInput
              label='Email address'
              name='email'
              type='email'
              placeholder={'Enter your email'}
              inputRef={register(formValidation('email', true))}
              readOnly={loading}
              error={errors.email}
              errorMessage={errors.email && errors.email.message}
            />
          </div>
          <div className='w-full md:w-1/3 md:pl-2'>
            <FormInput
              label='Phone number'
              name='mobile'
              type='text'
              placeholder={'08012345678'}
              inputRef={register(formValidation('text', true))}
              readOnly={loading}
              error={errors.mobile}
              errorMessage={errors.mobile && errors.mobile.message}
            />
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-between'>
          <div className='w-full md:w-1/2 md:pr-2'>
            <FormInput
              label='Password'
              placeholder={'Enter your password'}
              name='password'
              type='password'
              inputRef={register(formValidation('password', true))}
              readOnly={loading}
              error={errors.password}
              errorMessage={errors.password && errors.password.message}
              onInput={(e) => setPassword(e.target.value)}
              infos={[
                'Password should contain minimum of 8 characters, 1 caps letter and 1 number',
              ]}
            />
          </div>

          <div className='w-full md:w-1/2 md:pl-2'>
            <FormInput
              label='Confirm Password'
              placeholder={'Confirm your password'}
              name='confirm_password'
              type='password'
              inputRef={register(formValidation('password', true))}
              onInput={(e) => setConfirmPassword(e.target.value)}
              readOnly={loading}
              error={confirmPassword !== Password && confirmPassword.trim()}
              errorMessage={'Password does not match'}
            />
          </div>
        </div>
        <hr className='divider mt-0 mb-2' />
        <FormInput
          label='Do you have a referral code?'
          name='referral_code'
          type='text'
          value={referral_code}
          placeholder={'Enter code here'}
          inputRef={register(formValidation('text', false))}
          readOnly={loading}
          error={errors.referral_code}
          errorMessage={errors.referral_code && errors.referral_code.message}
          onInput={(e) => {
            setReferralCode(e.target.value);
          }}
        />
        <hr className='divider mt-0 mb-2' />
        <div className='d_flex remember_section'>
          <div className='flex flex-row'>
            <FormInput
              label=''
              name='agreement'
              type='checkbox'
              inputRef={register(formValidation('checkbox', true))}
              readOnly={loading}
              onInput={(e) => {
                setTerms(e.target.checked);
              }}
              error={errors.agreement}
              errorMessage={errors.agreement && errors.agreement.message}
              CustomLabel={
                <span className={'p4-medium text-black mt-1 pl-3'}>
                  I agree with{' '}
                  <a
                    href='https://www.ropay.africa/terms-of-service'
                    target='_blank'
                    rel='noreferrer'
                    className='cursor-pointer hover:underline p4-bold text-primary-2 underline'
                  >
                    terms & conditions
                  </a>
                </span>
              }
            />
          </div>
        </div>

        <Button
          text='Sign up'
          type='submit'
          loading={loading}
          disabled={!isValid && !terms}
        />
      </form>
    </AuthLayout>
  );
};

export default RegisterPage;
